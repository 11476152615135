<template>
  <section class="bg-black">
    <p
      class="mt-2 text-3xl font-extrabold text-white tracking-tight sm:text-4xl lg:pt-8 lg:px-8 text-left"
    >
      What people are saying
    </p>
    <div class="max-w-7xl mx-auto md:grid md:grid-cols-3 md:px-6 lg:px-8">
      <div
        class="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:px-8"
      >
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-light text-white md:flex-grow">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
              fill="currentColor"
              viewBox="0 0 32 32"
              aria-hidden="true"
            >
              <path
                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
              />
            </svg>
            <p class="relative" v-html="testimonials[0].text"></p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="ml-4">
                <div class="text-base font-medium text-white">- {{ testimonials[0].name }}</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      <div
        class="py-12 px-4 sm:px-6 md:py-16 md:pr-0 md:pl-10 lg:px-8"
      >
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-light text-white md:flex-grow">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
              fill="currentColor"
              viewBox="0 0 32 32"
            >
              <path
                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
              />
            </svg>
            <p class="relative" v-html="testimonials[1].text"></p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="ml-4">
                <div class="text-base font-medium text-white">
                  - {{ testimonials[1].name }}
                </div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
      <div
        class="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 lg:px-8"
      >
        <blockquote class="mt-6 md:flex-grow md:flex md:flex-col">
          <div class="relative text-lg font-light text-white md:flex-grow">
            <svg
              class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-gray-600"
              fill="currentColor"
              viewBox="0 0 32 32"
              aria-hidden="true"
            >
              <path
                d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z"
              />
            </svg>
            <p class="relative" v-html="testimonials[2].text"></p>
          </div>
          <footer class="mt-8">
            <div class="flex items-start">
              <div class="ml-4">
                <div class="text-base font-medium text-white">- {{ testimonials[2].name }}</div>
              </div>
            </div>
          </footer>
        </blockquote>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Testimonials",
  components: {
  },
  data: function() {
    return {
      testimonials: [
        {
          id: 1,
          text: 'From the beginning I felt accompanied and well informed about all the processes that should be followed. <br><br>Communication never failed and all my doubts were solved.',
          name: 'Ruben L.',
        },
        {
          id: 2,
          text: 'Mary contacted me for a position and the first thing that caught my attention was that the filter she made of my profile and the position to be covered made a very good match. <br><br>During the recruitment process, she gave me a lot of support and encouraged me before the interviews.',
          name: 'Grisell C.',
        },
        {
          id: 3,
          text: 'I want to thank the support that Luz Vistraín gave during my hiring process to Vision. <br><br>She always had an excellent treatment, and is extremely professional and proactive as well as efficient, since at all times she solved my doubts and followed up my case.',
          name: 'Lizette B.',
        },
        {
          id: 4,
          text: 'I want to thank the support of Yaneli during my process, she was always very attentive to my requests and adapted to my times to follow up the process. <br><br>I hope to keep in touch with ScreenIT, I’m so grateful with your kindness and proactivity to solve problems',
          name: 'Mariano O.',
        }
      ],
    };
  },
}
</script>

<style scoped></style>
