<template>
  <div
    class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:my-12"
  >
    <p
      class="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl lg:my-8"
    >
      Recieve guidance from an
      <br />
      empowering group of recruiters
    </p>
    <router-link
      class="block w-full py-3 px-12 text-center bg-black border border-transparent shadow-md text-lg font-light text-white hover:bg-gray-700 sm:inline-block sm:w-auto"
      to="/recruiters"
      >Meet the team</router-link
    >
    <div class="mt-12 sm:hidden">
      <hooper
        :items-to-show="1.25"
        :center-mode="true"
        :infinite-scroll="true"
        style="height: 50%"
      >
        <slide v-for="member in members" :key="member.name">
          <div class="space-x-2 xl:space-x-4 h-auto">
            <img
              class="object-contain h-full w-full"
              :src="member.imageUrl"
              alt="Meet the team 3"
            />
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </div>
    <div class="mt-12 sm:block hidden">
      <hooper
        :items-to-show="3.25"
        :center-mode="true"
        :infinite-scroll="true"
        style="height: 50%"
      >
        <slide v-for="member in members" :key="member.name">
          <div class="space-x-2 xl:space-x-4 h-auto">
            <img
              class="object-contain h-full w-full"
              :src="member.imageUrl"
              alt="Meet the team 3"
            />
          </div>
        </slide>
        <hooper-navigation slot="hooper-addons"></hooper-navigation>
      </hooper>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide, Navigation as HooperNavigation } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "MeetTheTeam",
  components: {
    Hooper,
    Slide,
    HooperNavigation,
  },
  data: function() {
    return {
      members: [
        { imageUrl: "/images/maryhome1.jpg", name: "Mary" },
        { imageUrl: "/images/luzhome1.jpg", name: "Luz" },
        { imageUrl: "/images/verohome1.jpg", name: "Vero" },
        { imageUrl: "/images/carhome1.jpg", name: "Carmen" },
        { imageUrl: "/images/yanehome1.jpg", name: "Yane" },
        { imageUrl: "/images/franhome1.jpg", name: "Fran" },
      ],
    };
  },
};
</script>

<style scoped></style>
