<template>
  <div class="bg-white py-16 sm:py-0">
    <div class="relative sm:py-16">
      <div aria-hidden="true">
        <div class="absolute inset-0">
          <img
            class="h-full w-full object-cover"
            src="/images/suscribe.jpg"
            alt="Join us"
          />
          <div class="absolute inset-0" style="mix-blend-mode: multiply"></div>
        </div>
      </div>
      <div
        class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-7xl lg:px-0"
      >
        <div class="relative px-6 py-10 overflow-hidden sm:px-12 sm:py-20">
          <div class="relative">
            <div class="sm:text-center">
              <h2
                class="text-3xl font-medium text-white tracking-tight sm:text-4xl"
              >
                Suscribe to our newsletter.
              </h2>
              <p class="mt-6 mx-auto max-w-2xl text-lg text-white">
                Sign up to recieve news and updates.
              </p>
            </div>
            <form
              @submit.prevent="showPopup"
              class="mt-12 sm:mx-auto sm:max-w-lg sm:flex"
            >
              <div class="min-w-0 flex-1">
                <label for="cta_email" class="sr-only">Email address</label>
                <input
                  id="cta_email"
                  type="email"
                  v-model="email"
                  class="block w-full border border-transparent px-5 py-3 text-base text-gray-900 placeholder-gray-500 shadow-sm focus:outline-none focus:border-transparent focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600"
                  :class="{
                    'border-red-500': !validEmail,
                    'placeholder-red-500': !validEmail,
                    'text-red-500': !validEmail,
                  }"
                  placeholder="Enter your email address"
                />
              </div>
              <div class="mt-4 sm:mt-0 sm:ml-3">
                <button
                  type="submit"
                  class="block w-full px-5 py-3 bg-blue-600 text-lg font-light text-white shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600 sm:px-10"
                >
                  Sign up
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <pop-up-subscribed
      :popupDisplayed.sync="popupDisplayed"
    ></pop-up-subscribed>
  </div>
</template>

<script>
import PopUpSubscribed from "./PopUpSubscribed.vue";

export default {
  name: "Newsletter",
  components: {
    PopUpSubscribed,
  },
  data: function() {
    return {
      popupDisplayed: "None",
      email: null,
    };
  },
  computed: {
    validEmail() {
      if (!this.email) return false;
      const mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (this.email.match(mailformat)) {
        return true;
      }
      return false;
    },
  },
  methods: {
    showPopup() {
      if (!this.validEmail) return;
      this.popupDisplayed = "Subscribed";
      this.email = null;
    },
  },
};
</script>

<style scoped></style>
