<template>
  <footer class="bg-white" aria-labelledby="footerHeading">
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
      <div class="lg:grid lg:grid-cols-4 lg:gap-8">
        <div class="space-y-8 lg:col-span-1">
          <img
            class="mx-auto h-24"
            src="/images/logo_careers.png"
            alt="ScreenIT"
          />
        </div>
        <div class="mt-12 grid grid-cols-1 gap-8 lg:mt-0 lg:col-span-1">
          <div class="text-left mt-12 md:mt-0">
            <h3 class=" text-md text-gray-900 tracking-wider">
              Héroe de Nacozari 25b
            </h3>
            <h3 class="mt-2 text-md text-gray-900 tracking-wider">
              Centro, Querétaro
            </h3>
            <h3 class="mt-2 text-md text-gray-900 tracking-wider">
              México
            </h3>
            <h3 class="mt-4 text-md text-gray-900 tracking-wider">
              Querétaro: +52 (442) 290 8254
            </h3>
            <h3 class="mt-2 text-md text-gray-900 tracking-wider">
              México: 018001121211
            </h3>
            <h3 class="mt-2 text-md text-gray-900 tracking-wider">
              USA&CAN: (832) 699 0173
            </h3>
          </div>
        </div>
        <div class="mt-12 grid grid-cols-1 gap-8 lg:mt-0 lg:col-span-1">
          <div class="md:grid md:grid-cols-1 md:gap-8">
            <div class="mt-12 md:mt-0">
              <h3
                class="text-sm font-semibold text-gray-400 tracking-wider uppercase"
              >
                Legal
              </h3>
              <ul class="mt-2 space-y-2">
                <li>
                  <a
                    href="/docs/privacy-policy-screenit.pdf"
                    target="_blank"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Privacy
                  </a>
                </li>

                <li v-if="false">
                  <a
                    href="#"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Terms of use
                  </a>
                </li>

                <li v-if="false">
                  <a
                    href="#"
                    class="text-base text-gray-500 hover:text-gray-900"
                  >
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="">
          <div class="flex space-x-6">
            <a href="https://www.facebook.com/screenit.FanPage/?fref=ts" target="_blank">
              <span class="sr-only">Facebook</span>
              <img class="w-6" src="/images/logo_facebook.png" alt="Facebook logo" />
            </a>

            <a href="https://www.instagram.com/screenit_tas/" target="_blank">
              <span class="sr-only">Instagram</span>
              <img class="w-6" src="/images/logo_instagram.png" alt="Instagram logo" />
            </a>

            <a href="https://twitter.com/screenit_TAS" target="_blank">
              <span class="sr-only">Twitter</span>
              <img class="w-6" src="/images/logo_twitter.png" alt="Twitter logo" />
            </a>

            <a href="https://www.linkedin.com/company/screenit---talent-attraction-services" target="_blank">
              <span class="sr-only">Linkedin</span>
              <img class="w-6" src="/images/logo_linkedin.png" alt="Linkedin logo" />
            </a>

            <a href="https://www.youtube.com/channel/UCWWGM_Tqf_P_wYFtne7XnhA" target="_blank">
              <span class="sr-only">Youtube</span>
              <img class="w-6" src="/images/logo_youtube.png" alt="Youtube logo" />
            </a>
          </div>
        </div>
      </div>
      <div class="mt-12 border-t border-gray-200 pt-8">
        <p class="text-base text-gray-400 lg:text-center">
          &copy; 2021 ScreenIT. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  components: {},
}
</script>
<style scoped></style>
