<template>
  <div class="relative py-16 bg-white">
    <div
      class="hidden absolute top-0 inset-x-0 h-1/2 md:block"
      aria-hidden="true"
    ></div>
    <div class="max-w-7xl mx-auto bg-white bg-transparent md:px-8">
      <div class="md:grid md:grid-cols-12">
        <div
          class="relative z-9 md:col-start-2 md:row-start-1 md:col-span-6 md:bg-transparent"
        >
          <div
            class="absolute inset-x-0 h-1/2 bg-gray-50 md:hidden"
            aria-hidden="true"
          ></div>
          <div
            class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 md:max-w-none md:p-0"
          >
            <div
              class="aspect-w-10 aspect-h-6 sm:aspect-w-15 sm:aspect-h-14 md:aspect-w-10 md:aspect-h-9 lg:aspect-w-3 lg:aspect-h-2"
            >
              <img
                class="object-cover object-center shadow-2xl"
                src="/images/step_in.jpg"
                alt="Step in"
              />
            </div>
          </div>
        </div>

        <div
          class="relative bg-white md:col-start-7 md:row-start-1 md:my-8 md:col-span-6 sm:grid md:grid-cols-10 sm:items-center"
        >
          <div
            class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 md:max-w-none md:p-0 md:col-start-2 lg:col-start-2 md:col-span-7 text-left lg:col-span-6"
          >
            <h2 class="lg:text-2xl md:text-lg md:font-bold lg:font-extrabold text-black" id="join-heading">
              Our success is due
              <br />
              to teamwork
            </h2>
            <p class="lg:text-lg md:text-medium text-black">
              We only consider candidates who are enthusiastic learners,
              diligent listeners, and pro-active doers.
            </p>

            <router-link
              class="block w-full py-2 px-16 text-center bg-black border border-transparent shadow-md text-lg font-light text-white hover:bg-gray-700 sm:inline-block sm:w-auto"
              to="/jobs"
              >Step in</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StepIn",
  components: {},
};
</script>
<style scoped></style>
