<template>
  <div class="bg-black max-w-7xl mx-auto sm:px-6 lg:px-0">
    <div class="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-12">
      <div class="space-y-12 sm:hidden">
        <div
          class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
        >
          <h2
            class="text-2xl font-bold text-white tracking-tight sm:text-4xl text-left"
          >
            Recently in!
          </h2>
        </div>
        <hooper
          :items-to-show="1"
          :infinite-scroll="true"
          :auto-play="true"
          :play-speed="2000"
          style="height: 100%"
        >
          <slide v-for="newHire in newHires" :key="newHire.name">
            <div class="space-y-6 xl:space-y-10">
              <img
                class="mx-auto h-40 w-40 rounded xl:w-56 xl:h-56 object-cover"
                :src="newHire.imageUrl"
                :alt="newHire.name"
              />
              <div
                class="mx-12 space-y-2 xl:flex xl:items-center xl:justify-between"
              >
                <div
                  class="font-medium text-lg leading-6 space-y-1 w-full text-center"
                >
                  <h3 class="text-white">{{ newHire.name }}</h3>
                  <p class="text-white" v-if="newHire.location">
                    {{ newHire.location }},
                  </p>
                  <p class="text-white" v-if="newHire.position">
                    {{ newHire.position }},
                  </p>
                  <p class="text-white" v-if="newHire.company && showCompany">
                    {{ newHire.company }}
                  </p>
                </div>
              </div>
            </div>
          </slide>
        </hooper>
      </div>
      <div class="space-y-12 sm:block hidden">
        <div
          class="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none"
        >
          <h2
            class="text-2xl font-bold text-white tracking-tight sm:text-4xl text-left"
          >
            Recently in!
          </h2>
        </div>
        <hooper
          :items-to-show="4"
          :infinite-scroll="true"
          :auto-play="true"
          :play-speed="2000"
          style="height: 100%"
        >
          <slide v-for="newHire in newHires" :key="newHire.name">
            <div class="space-y-6 xl:space-y-10">
              <img
                class="mx-auto h-40 w-40 rounded xl:w-56 xl:h-56 object-cover"
                :src="newHire.imageUrl"
                :alt="newHire.name"
              />
              <div
                class="mx-12 space-y-2 xl:flex xl:items-center xl:justify-between"
              >
                <div
                  class="font-medium text-lg leading-6 space-y-1 w-full text-center"
                >
                  <h3 class="text-white">{{ newHire.name }}</h3>
                  <p class="text-white" v-if="newHire.location">
                    {{ newHire.location }},
                  </p>
                  <p class="text-white" v-if="newHire.position">
                    {{ newHire.position }},
                  </p>
                  <p class="text-white" v-if="newHire.company && showCompany">
                    {{ newHire.company }}
                  </p>
                </div>
              </div>
            </div>
          </slide>
        </hooper>
      </div>
    </div>
  </div>
</template>

<script>
import { Hooper, Slide } from "hooper";
import "hooper/dist/hooper.css";

export default {
  name: "RecentlyIn",
  components: {
    Hooper,
    Slide,
  },
  data: function() {
    return {
      newHires: [
        {
          imageUrl: "/images/recently_in_1.jpg",
          name: "Sergio",
          location: "",
          position: "Unity Developer",
          company: "Wildworks",
        },
        {
          imageUrl: "/images/recently_in_2.jpg",
          name: "Mariel",
          location: "",
          position: "IT Sr Recruiter",
          company: "Deloitte",
        },
        {
          imageUrl: "/images/recently_in_3.jpg",
          name: "Aldo",
          location: "",
          position: "TIER-1 Engineer",
          company: "CieloIT",
        },
        {
          imageUrl: "/images/recently_in_4.jpg",
          name: "Jassim Alfredo",
          location: "",
          position: "",
          company: "",
        },
        {
          imageUrl: "/images/recently_in_5.jpg",
          name: "Angel Perez",
          location: "",
          position: "",
          company: "",
        },
        {
          imageUrl: "/images/recently_in_6.png",
          name: "Juan Eduardo",
          location: "",
          position: "IT Recruiter",
          company: "",
        },
        {
          imageUrl: "/images/recently_in_7.png",
          name: "Edgar Negrete",
          location: "",
          position: "IT Recruiter",
          company: "",
        },
      ],
      showCompany: false,
    };
  },
};
</script>

<style scoped></style>
