<template>
  <!-- Hero section -->
  <div class="relative">
    <div class="max-w-7xl mx-auto xl:px-0">
      <div class="relative shadow-xl md:overflow-hidden">
        <div class="absolute inset-0">
          <vue-video-section
            :elementId="'header-background-video'"
            :ref="'header-background-video'"
            :mp4Source="
              require('../../../public/media/ClipHomePageScreenIT_Optimized.mp4')
            "
            :mobileBreakpoint="640"
            :desktopHeight="475"
            :mobileHeight="310"
            :playsinline="true"
            :loop="true"
            :autoplay="true"
            :autobuffer="true"
            :muted="true"
          >
            <div slot="overlay-content" class="overlay-content">
              <div
                class="relative px-4 py-16 sm:pb-12 sm:pt-72 md:px-8 sm:px-12 md:pb-12 md:pt-64 xl:px-8"
              >
                <h1
                  class="text-left text-3xl font-medium tracking-tight md:text-5xl xl:text-6xl text-white md:w-3/4 sm:w-2/5"
                >
                  <span class="block tracking-normal"
                    >We care about the people behind the talent</span
                  >
                </h1>
                <div
                  class="mt-8 max-w-sm md:mx-auto md:max-w-none md:flex md:justify-left sm:justify-left"
                >
                  <router-link
                    class="flex items-center justify-center px-10 py-2 border border-transparent text-lg font-light shadow-sm text-white bg-blue-600 bg-opacity-100 hover:bg-opacity-80 md:px-8 sm:px-4"
                    to="/jobs"
                    >Join our team</router-link
                  >
                </div>
              </div>
            </div>
          </vue-video-section>
          <div class="absolute inset-0" style="mix-blend-mode: multiply"></div>
        </div>
        <div
          class="relative px-4 py-16 sm:pb-12 sm:pt-72 md:px-8 sm:px-12 md:pb-12 md:pt-64 xl:px-8"
        >
          <h1
            class="text-left text-3xl font-medium tracking-tight md:text-5xl xl:text-6xl text-white md:w-3/4 sm:w-2/5"
          >
            <span class="block tracking-normal"
              >We care about the people behind the talent</span
            >
          </h1>
          <div
            class="mt-8 max-w-sm md:mx-auto md:max-w-none md:flex md:justify-left sm:justify-left"
          >
            <router-link
              class="flex items-center justify-center px-10 py-2 border border-transparent text-lg font-light shadow-sm text-white bg-blue-600 bg-opacity-100 hover:bg-opacity-80 md:px-8 sm:px-4"
              to="/jobs"
              >Join our team</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-video-section/dist/vue-video-section.css";

import Vue from "vue";
import VueVideoSection from "vue-video-section";
Vue.component("vue-video-section", VueVideoSection);

export default {
  name: "JoinUs",
  components: {},
};
</script>

<style>
.vue-video-section__video-element {
  object-fit: cover;
}
</style>
