<template>
  <div class="bg-white">
    <main>
      <JoinUs></JoinUs>
      <StepIn></StepIn>
      <RecentlyIn></RecentlyIn>
      <MeetTheTeam></MeetTheTeam>
      <Testimonials></Testimonials>
      <Video></Video>
      <AvailablePositions></AvailablePositions>
      <Newsletter></Newsletter>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import JoinUs from '@/components/home/JoinUs.vue';
import StepIn from '@/components/home/StepIn.vue';
import RecentlyIn from '@/components/home/RecentlyIn.vue';
import MeetTheTeam from '@/components/home/MeetTheTeam.vue';
import Testimonials from '@/components/home/Testimonials.vue';
import Video from '@/components/home/Video.vue';
import AvailablePositions from '@/components/home/AvailablePositions.vue';
import Newsletter from '@/components/home/Newsletter.vue';

export default {
  name: 'Home',
  components: {
    JoinUs,
    StepIn,
    RecentlyIn,
    MeetTheTeam,
    Testimonials,
    Video,
    AvailablePositions,
    Newsletter,
  }
}
</script>
