<template>
  <!-- This example requires Tailwind CSS v2.0+ -->
  <nav class="bg-black">
    <div class="max-w-7xl mx-auto px-2 md:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 right-0 flex items-center md:hidden">
          <!-- Mobile menu button-->
          <button
            @click="show = !show"
            type="button"
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
            aria-controls="mobile-menu"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <!--
                Icon when menu is closed.

                Heroicon name: outline/menu

                Menu open: "hidden", Menu closed: "block"
            -->
            <svg
              class="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!--
                Icon when menu is open.

                Heroicon name: outline/x

                Menu open: "block", Menu closed: "hidden"
            -->
            <svg
              class="hidden h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="flex-1 flex items-center justify-left md:items-stretch md:justify-start"
        >
          <div class="flex-shrink-0 flex items-center">
            <router-link
              to="/"
            >
              <img
                class="block lg:hidden h-16 w-auto"
                src="/images/logo_careers_home.png"
                alt="screenIT"
              />
              <img
                class="hidden lg:block h-8 w-auto"
                src="/images/logo_careers_home.png"
                alt="screenIT"
              />
            </router-link>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 md:static md:inset-auto md:ml-6 md:pr-0"
        >
          <div class="hidden md:block md:ml-6">
            <div class="flex space-x-4">
              <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
              <router-link
                class="text-white px-3 py-2 rounded-md text-sm font-medium"
                to="/"
                >Home</router-link
              >
              <router-link
                class="text-white px-3 py-2 rounded-md text-sm font-medium"
                to="/jobs"
                >Jobs</router-link
              >
              <router-link
                class="text-white px-3 py-2 rounded-md text-sm font-medium"
                to="/rewards"
                >Rewards</router-link
              >
              <router-link
                class="text-white px-3 py-2 rounded-md text-sm font-medium"
                to="/recruiters"
                >Our team</router-link
              >
              <router-link
                class="text-white px-3 py-2 rounded-md text-sm font-medium"
                to="/blog"
                v-if="showBlog"
                >Blog</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Mobile menu, show/hide based on menu state. -->
    <collapse-transition group>
      <div class="md:hidden" id="mobile-menu" v-if="show" key="show">
        <div class="px-2 pt-2 pb-3 space-y-1">
          <!-- Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" -->
          <router-link
            class="block text-white px-3 py-2 rounded-md text-base font-medium"
            to="/"
            @click.native="show = false"
            >Home</router-link
          >
          <router-link
            class="block text-white px-3 py-2 rounded-md text-base font-medium"
            to="/jobs"
            @click.native="show = false"
            >Jobs</router-link
          >
          <router-link
            class="block text-white px-3 py-2 rounded-md text-base font-medium"
            to="/rewards"
            @click.native="show = false"
            >Rewards</router-link
          >
          <router-link
            class="block text-white px-3 py-2 rounded-md text-base font-medium"
            to="/recruiters"
            @click.native="show = false"
            >Our team</router-link
          >
          <router-link
            class="block text-white px-3 py-2 rounded-md text-base font-medium"
            to="/blog"
            @click.native="show = false"
            v-if="showBlog"
            >Blog</router-link
          >
        </div>
      </div>
    </collapse-transition>
  </nav>
</template>

<script>
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "Navbar",
  components: {
    CollapseTransition,
  },
  data() {
    return {
      show: false,
      showBlog: false,
    };
  },
};
</script>

<style scoped></style>
