import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/jobs',
    name: 'Jobs',
    // route level code-splitting
    // this generates a separate chunk (jobs.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "jobs" */ '../views/Jobs.vue')
  },
  {
    path: '/jobs/all',
    name: 'All Jobs',
    // route level code-splitting
    // this generates a separate chunk (jobs.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "all_jobs" */ '../views/AllJobs.vue')
  },
  {
    path: '/rewards',
    name: 'Rewards',
    component: () => import(/* webpackChunkName: "rewards" */ '../views/Rewards.vue')
  },
  {
    path: '/recruiters',
    name: 'Recruiters',
    component: () => import(/* webpackChunkName: "recruiters" */ '../views/Recruiters.vue')
  },
  {
    path: '/recruiter/:id',
    name: 'Recruiter',
    component: () => import(/* webpackChunkName: "recruiter" */ '../views/RecruiterDetails.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import(/* webpackChunkName: "blog" */ '../views/Blog.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
