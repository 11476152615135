<template>
  <div
    class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:mt-12"
  >
    <div class="wise-iframe-wrapper">
      <iframe
        width="730"
        height="548"
        src="https://www.youtube.com/embed/ZRGqMlVH9XU"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
    <div
      class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:mt-8"
    >
      <router-link
        class="block w-full py-3 px-12 text-center bg-black border border-transparent shadow-md text-lg font-light text-white hover:bg-gray-700 sm:inline-block sm:w-auto"
        to="/jobs"
        >Join the team</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Video",
  components: {},
};
</script>

<style>
.wise-iframe-wrapper {
    position: relative;
    padding-bottom: 42.10%;
    margin: 0 auto;
    height: 0;
    overflow: hidden;
    max-width: 750px;
}
 
.wise-iframe-wrapper iframe,
.wise-iframe-wrapper object,
.wise-iframe-wrapper embed {
    position: absolute;
    width: 100%;
    height: 100%;
    max-width: 750px;
    max-height: 500px;
}
</style>
