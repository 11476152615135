<template>
  <div class="relative py-16 bg-white">
    <div
      class="hidden absolute top-0 inset-x-0 h-1/2 lg:block"
      aria-hidden="true"
    ></div>
    <div class="max-w-7xl mx-auto bg-white lg:bg-transparent lg:px-8">
      <div class="lg:grid lg:grid-cols-12">
        <div
          class="relative z-9 lg:col-start-6 lg:row-start-1 lg:col-span-5 lg:bg-transparent"
        >
          <div
            class="absolute inset-x-0 h-1/2 bg-gray-50 lg:hidden"
            aria-hidden="true"
          ></div>
          <div
            class="max-w-md mx-auto px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:p-0"
          >
            <div
              class="aspect-w-10 aspect-h-6 sm:aspect-w-2 sm:aspect-h-1 lg:aspect-w-3 lg:aspect-h-2"
            >
              <img
                class="object-cover object-center shadow-2xl"
                src="/images/available_positions.jpg"
                alt="Available positions"
              />
            </div>
          </div>
        </div>

        <div
          class="relative bg-white lg:col-start-3 lg:row-start-1 lg:my-8 lg:col-span-4 lg:grid lg:grid-cols-12 lg:items-center"
        >
          <div
            class="relative max-w-md mx-auto py-12 px-4 space-y-6 sm:max-w-3xl sm:py-16 sm:px-6 lg:max-w-none lg:p-0 lg:col-start-2 lg:col-span-10 text-left"
          >
            <h2 class="text-2xl font-extrabold text-black" id="join-heading">
              Work from anywhere
            </h2>
            <p class="text-lg text-black">
              We are experts in remote work flow, so most of our vacancies are
              home ofﬁce and goal oriented.
            </p>
            <router-link
              class="block w-full py-3 px-8 text-center bg-black border border-transparent shadow-md text-lg font-light text-white hover:bg-gray-700 sm:inline-block sm:w-auto"
              to="/jobs"
              >See available positions</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AvailablePositions",
  components: {},
};
</script>

<style scoped></style>
